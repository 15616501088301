<template>
  <div class="home">
    <Navbar />
    <v-container class="my-5">
      <router-view v-slot="{ Component, route }">
        <transition
          :name="route.meta.transition || 'fade'"
          mode="out-in"
          appear
        >
          <keep-alive>
            <suspense>
              <template #default>
                <component
                  :is="Component"
                  :key="route.meta.usePathKey ? route.path : undefined"
                />
              </template>
              <template #fallback> Loading... </template>
            </suspense>
          </keep-alive>
        </transition>
      </router-view>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/partials/Navbar";
export default {
  name: "WebApp",
  components: { Navbar },
  data() {
    return {};
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, 
.fade-leave-to {
  opacity: 0;
}
</style>